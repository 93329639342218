@defer (when dataLoaded) {
  <p-table
    [value]="processList"
    styleClass="p-datatable-gridlines p-datatable-striped"
    stateStorage="session"
    stateKey="processList-session"
    [paginator]="true"
    [rows]="20"
    rowGroupMode="rowspan"
    groupRowsBy="client.id"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
    [(selection)]="selectedProcess"
  >
  <ng-template pTemplate="header">
    <tr>
      @if(cliId == '') {
        <th class="text-center" pSortableColumn="client.id">{{ 'Licence' | transloco }} <p-sortIcon field="client.id"></p-sortIcon></th>
      }
      <th class="text-center" pSortableColumn="tpr_name_clean">{{ 'Nom du process' | transloco }}<p-sortIcon field="tpr_name_clean"></p-sortIcon></th>
      <th pSortableColumn="tpr_stats_treatments" class="text-center">{{ 'Stats traitement' | transloco }}<p-sortIcon field="tpr_stats_treatments"></p-sortIcon></th>
      <th pSortableColumn="tpr_stats_lots" class="text-center">{{ 'Stats Lots' | transloco }}<p-sortIcon field="tpr_stats_lots"></p-sortIcon></th>
      <th pSortableColumn="tpr_stats_photos" class="text-center">{{ 'Stats Photos' | transloco }}<p-sortIcon field="tpr_stats_photos"></p-sortIcon></th>
      <th pSortableColumn="tpr_auto_close" class="text-center">{{ 'Cloture auto' | transloco }}<p-sortIcon field="tpr_auto_close"></p-sortIcon></th>
    @if(canWrite()) {
    <th class="text-center">Actions</th>
    }
    </tr>
   <tr>
    @if(cliId == '') {
      <th>
          <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="licences"
                [placeholder]="'Toutes' | transloco"
                (onChange)="filter($event.value)"
                optionLabel="name"
                optionValue="id"
                [showToggleAll]="false"
                class="liste-recherche-table"
              >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
      </th>
    }
    <th>
    <p-columnFilter type="text" field="tpr_name_clean" matchMode="contains"></p-columnFilter>
    </th>
    <th class="text-center">
    <p-columnFilter type="boolean" field="tpr_stats_treatments" class="custom-filter"></p-columnFilter>
    </th>
    <th class="text-center">
    <p-columnFilter type="boolean" field="tpr_stats_lots" class="custom-filter"></p-columnFilter>
    </th>
    <th class="text-center">
    <p-columnFilter type="boolean" field="tpr_stats_photos" class="custom-filter"></p-columnFilter>
    </th>
    <th class="text-center">
    <p-columnFilter type="boolean" field="tpr_auto_close" class="custom-filter"></p-columnFilter>
    </th>
    @if(canWrite()) {
    <th></th>
    }
    </tr>
  </ng-template>
  <ng-template pTemplate="body"
      let-rowgroup="rowgroup"
      let-rowspan="rowspan"
      let-process>
  <tr>
    @if(cliId == '') {
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan"
          [ngClass]="getGroupClass()">
          {{ process.client?.name }}
      </td>
    }
    <td>{{ process.tpr_name_clean }}</td>
    <td class="text-center">
    <i class="fa fa-lg" [ngClass]="{ 'text-green-500 fa-circle-check': process.tpr_stats_treatments, 'fa-regular fa-circle': !process.tpr_stats_treatments }"></i>
    </td>
    <td class="text-center">
    <i class="fa fa-lg" [ngClass]="{ 'text-green-500 fa-circle-check': process.tpr_stats_lots, 'fa-regular fa-circle': !process.tpr_stats_lots }"></i>
    </td>
    <td class="text-center">
    <i class="fa fa-lg" [ngClass]="{ 'text-green-500 fa-circle-check': process.tpr_stats_photos, 'fa-regular fa-circle': !process.tpr_stats_photos }"></i>
    </td>
    <td class="text-center">
    <i class="fa fa-lg" [ngClass]="{ 'text-green-500 fa-circle-check': process.tpr_auto_close, 'fa-regular fa-circle': !process.tpr_auto_close }"></i>
    </td>
    @if(canWrite()) {
      <td class="flex align-items-center justify-content-center">
        <button
          pButton
          type="button"
          icon="pi pi-trash"
          class="flex p-button-rounded p-button-unactivate mr-2"
          (click)="deleteTheProcess(process)"
          [pTooltip]="'Supprimer le process' | transloco"
          tooltipPosition="left"
        ></button>
        @if(process.tpr_hidden == 0) {
          <!-- Désactivé pour le moment -->
        <!-- <button
            pButton
            icon="pi pi-pencil"
            class="flex p-button-rounded p-button-success mr-2"
            (click)="updateTheProcess(process)"
            [pTooltip]="'Modifier le process' | transloco"
            tooltipPosition="left"
          >
        </button> -->
        <button
            pButton
            icon="pi pi-pencil"
            class="flex p-button-rounded p-button-success mr-2"
            (click)="updateTheProcess(process)"
            [pTooltip]="'Modifier le process' | transloco"
            tooltipPosition="left"
          >
        </button>
        <button
          pButton
          type="button"
          icon="pi pi-eye"
          class="flex p-button-rounded p-button-unactivate mr-2"
          [pTooltip]="'Masquer le process' | transloco"
          tooltipPosition="left"
          (click)="hideTheProcess(process, 1)"
        >
        </button>
        } @else {
        <button
          pButton
          type="button"
          icon="pi pi-eye-slash"
          class="flex p-button-rounded p-button-activate mr-2"
          [pTooltip]="'Afficher le process' | transloco"
          tooltipPosition="left"
          (click)="hideTheProcess(process, 0)"
        >
        </button>
        }
        <button
          pButton
          type="button"
          icon="pi pi-cog"
          class="flex p-button-rounded p-button-success mr-2"
          (click)="goToParamProcess(process)"
          [pTooltip]="'Accés aux paramètres' | transloco"
          tooltipPosition="left"
        >
        </button>
        <button
          pButton
          type="button"
          icon="pi pi-print"
          class="flex p-button-rounded p-button-success mr-2"
          (click)="outputSettings(process)"
          [pTooltip]="'Accés aux outputs' | transloco"
          tooltipPosition="left"
        >
        </button>
        <button
          pButton
          type="button"
          icon="pi pi-clone"
          class="flex p-button-rounded p-button-green mr-2"
          [pTooltip]="'Copier le process' | transloco"
          tooltipPosition="left"
          (click)="copyProcess(process); showDialog(process)"
        >
        </button>
      </td>
      }
</tr>
</ng-template>
</p-table>

<div class="card flex justify-content-center">
<p-dialog
header="Header"
[(visible)]="visible"
[modal]="true"
[style]="{ width: '40rem', height: '40rem' }">
<ng-template pTemplate="header">
<div class="inline-flex align-items-center justify-content-center gap-2">
<span class="font-bold white-space-nowrap">
Copie du process {{ process_name }}
</span>
</div>
</ng-template>
<span class="p-text-secondary block mb-5">
Sélectionner les licences cibles
</span>

<div class="flex align-items-center gap-3 mb-5">
<p-multiSelect
[(ngModel)]="selectedLicences"
scrollHeight="250px"
[options]="licences"
[placeholder]="'Toutes' | transloco"
(onChange)="onLicenceChange($event)"
optionLabel="name"
optionValue="id"
class="liste-recherche-table"
>
</p-multiSelect>
</div>
  <ng-template pTemplate="footer">
    <button
      pButton
      [label]="'Annuler' | transloco"
      icon="pi pi-minus"
      class="p-button-unactivate"
      (click)="visible = false"
    ></button>
    <button
      pButton
      [label]="'Enregistrer' | transloco"
      [disabled]="!enabledSave"
      icon="fa-solid fa-gear"
      class="p-button-success"
      (click)="copyProcessOnTargets()"
    ></button>
  </ng-template>
</p-dialog>
</div>

}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

